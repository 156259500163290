import { LogIn } from "react-feather"

// ** Initial State
const initialState = {
  tasks: [],
  selectedTask: {},
  selectedTag: {},
  tags:[],
  params: {
    filter: '',
    q: '',
    sort: '',
    tag: ''
  }
}

const TodoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TASKS':
      return { ...state, tasks: action.tasks}
    case 'GET_PENDING_TASK':
      return { ...state, tasks: action.tasks}
    case 'UPDATE_TASKS':
      return { ...state }
    case 'REORDER_TASKS':
      return { ...state, tasks: action.tasks }
    case 'SELECT_TASK':
      return { ...state, selectedTask: action.selectedTask }
    case 'EDIT_TASK':
      return { ...state }
    case 'TRASH_TASK':
      return { ...state }
    case 'GET_TAGS':
      return { ...state, tags: action.tags }
    case 'DELETE_TAG':
      return { ...state }
    case 'DELETE_TASK':
      return { ...state }
    case 'GET_TAG':
      return { ...state, selectedTag: action.selectedTag }
    case 'EDIT_TAG':
      return { ...state }
    default:
      return state
  }
}
export default TodoReducer
